<template>
    <el-table
        :data="data"
        border
        style="width: 100%; border-bottom: 1px solid #EBEEF5;"
        >
        <el-table-column
            min-width="50"
            label="No">
            <div slot-scope="props">
                <span style="color: #101111;">{{ props.$index + 1 }}</span>
            </div>
        </el-table-column>
        <el-table-column
            min-width="200"
            prop="target_location"
            label="Target Location">
            <div slot-scope="props">
                <span v-if="props.row.target_location == 'National Targeting'"style="color: #101111;">{{ props.row.target_location }}</span>
                <div v-if="props.row.target_location == 'State'" style="display: flex; flex-direction: column;">
                    <span style="color: #101111;">States:</span>
                    <span style="color: #101111; font-weight: 600; font-size: 12px; word-break: keep-all !important;">{{ formatCommaSeparatedString(props.row.states) }}</span>
                    <span v-if="props.row.cities.length >= 1" style="width: 100%; border: 1px solid #e3e3e3;"></span>
                    <span v-if="props.row.cities.length >= 1" style="color: #101111;">Cities:</span>
                    <span v-if="props.row.cities.length >= 1" style="color: #101111; font-weight: 600; font-size: 12px; word-break: keep-all !important;">{{ formatCommaSeparatedString(props.row.cities) }}</span>
                </div>
                <div v-if="props.row.target_location == 'Zip Code'" style="display: flex; flex-direction: column;">
                    <span style="color: #101111;">Zip Code:</span>
                    <span style="color: #101111; font-weight: 600; font-size: 12px; word-break: keep-all !important;">{{ props.row.zip_code.split("\n").join(", ") }}</span>
                </div>
            </div>
        </el-table-column>
        <el-table-column
            min-width="120"
            prop="keyword"
            label="Keyword">
            <div slot-scope="props">
                <span style="color: #101111; word-break: keep-all !important;">{{ props.row.keyword }}</span>
            </div>
        </el-table-column>
        <el-table-column
            min-width="150"
            prop="count_without_geo"
            label="National Count">
            <div slot-scope="props">
                <span style="color: #101111;">{{ props.row.count_without_geo.toLocaleString('en-US') }}</span>
            </div>
        </el-table-column>
        <el-table-column
            min-width="150"
            prop="count"
            sortable
            label="Local Count">
            <div slot-scope="props">
                <span style="color: #101111;">{{ props.row.count.toLocaleString('en-US') }}</span>
            </div>
        </el-table-column>
        <el-table-column
            min-width="120"
            prop="status"
            align="center"
            :filters="[{ text: 'Success', value: 'Success' }, { text: 'Removed', value: 'Removed' }]"
            :filter-method="filterTagApproxLead"
            label="Status">
            <div slot-scope="props">
                <span v-if="props.row.count > 0"style="padding: 2px 10px; color: #67c23a; border: 1px solid #c2e7b0; border-color: #c2e7b0; background-color: #fff; border-radius: 4px; font-size: 12px;">Success</span>
                <span v-else style="padding: 2px 10px; color: #f56c6c; border: 1px solid #fbc4c4; border-color: #fbc4c4; background-color: #fff; border-radius: 4px; font-size: 12px;">No Result</span>
            </div>
        </el-table-column>
        <el-table-column
            min-width="120"
            prop="created_at"
            label="Created">
            <div slot-scope="props">
                <span style="color: #101111;">{{ props.row.created_at }}</span>
            </div>
        </el-table-column>
    </el-table>
</template>

<script>
import { Table, TableColumn } from 'element-ui';

export default {
    props: {
        data: {
            default: [],
            type: Array,
        },
    },
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
    },
    methods: {
        formatCommaSeparatedString(input){
            return input.split(",").map(item => item.trim()).join(", ")
        },
        filterTagApproxLead(value, row){
            if(value === 'Success'){
                return row.count > 0
            }

            if(value === 'Removed'){
                return row.count == 0
            }
        },
    },
}
</script>